@media only screen and (max-width: 991px) {

  .navbar-fixed-top {
    .container {
      width:100%;
    }
  }

  header {
    .v-container,
    .v-inner {
      .slogan {
        max-width:330px;
        font-size:35px;
        line-height:40px;
        span {
          font-size:52px;
          line-height:48px;
        }
      }
      .logo {
        width:250px;
        height:250px;
        padding-top:58px;

        img {
          max-width:150px;
        }

        &:before,
        &:after {
          width:250px;
          height:250px;
          background-size: 250px 250px;
        }
      }
    }
  }

  .title {
    width:200px;
    height:200px;
    margin:-100px auto 30px auto;
    .v-container {
      height:200px;
      .v-inner {
        h1 {
          font-size:1.5em;
          span {
            font-size:30px;
          }
          span + span {
            font-size:18px;
          }
        }
      }
    }
  }

  .btn-round {
    width:200px;
    height:200px;
    font-size:24px;
    line-height: 155px;
    &:after {
      font-size:40px;
      top:45px;
    }
    &:before {
      top:45px;
    }

    &:hover,
    &:focus {
      &:before {
        font-size:40px;
      }
    }
  }

  a.example {
    width:100%;
    float:left;

    .image-circle {
      width:280px;
      height:280px;
      &:before,
      &:after {
        width:280px;
        height:280px;
        background-size: 280px 280px;
      }
      img {
        max-width:250px;
      }
    }
  }

}