@media only screen and (min-width: 768px) {
  .menu-toggle {
    display: none; }
  .navbar-fixed-top {
    background: rgba(0, 0, 0, 0.4);
    border: 0;
    border-bottom: 0 solid rgba(255, 255, 255, 0.2);
    transition: ease all 0.2s;
    margin: 0; }
    .navbar-fixed-top .navbar-header .navbar-brand {
      height: 110px;
      padding: 5px 15px; }
      .navbar-fixed-top .navbar-header .navbar-brand span {
        float: left;
        margin-left: 20px;
        margin-top: 38px;
        font-size: 30px;
        color: white;
        font-weight: 300;
        font-family: "Montserrat", sans-serif; }
        .navbar-fixed-top .navbar-header .navbar-brand span strong {
          font-weight: 700; }
        .navbar-fixed-top .navbar-header .navbar-brand span strong + strong {
          color: #40ab3e; }
      .navbar-fixed-top .navbar-header .navbar-brand img {
        float: left;
        max-width: 105px; }
    .navbar-fixed-top #navbar .navbar-right li {
      position: relative; }
      .navbar-fixed-top #navbar .navbar-right li a {
        padding: 45px 30px 45px 20px;
        overflow: hidden;
        font-family: "Raleway", sans-serif;
        text-transform: lowercase;
        font-size: 18px;
        color: white;
        letter-spacing: 1px;
        text-decoration: none; }
        .navbar-fixed-top #navbar .navbar-right li a:before {
          content: "\f0d8";
          font-family: FontAwesome;
          left: 0;
          right: 0;
          bottom: -14px;
          position: absolute;
          display: block;
          margin: 0 auto;
          width: 10px;
          height: 14px;
          color: #40ab3e;
          line-height: 20px;
          font-size: 20px;
          transition: all 0.2s ease;
          z-index: 1000; }
        .navbar-fixed-top #navbar .navbar-right li a:hover, .navbar-fixed-top #navbar .navbar-right li a:focus {
          color: #40ab3e;
          position: relative; }
          .navbar-fixed-top #navbar .navbar-right li a:hover:before, .navbar-fixed-top #navbar .navbar-right li a:focus:before {
            bottom: -1px; }
      .navbar-fixed-top #navbar .navbar-right li.active a {
        background: transparent;
        color: #40ab3e;
        position: relative; }
        .navbar-fixed-top #navbar .navbar-right li.active a:before {
          content: "\f0d8";
          font-family: FontAwesome;
          left: 0;
          right: 0;
          bottom: -1px;
          position: absolute;
          display: block;
          margin: 0 auto;
          width: 10px;
          height: 14px;
          color: #40ab3e;
          line-height: 20px;
          font-size: 20px;
          z-index: 1000;
          text-indent: 0; }
      .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu {
        padding: 0;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        left: 50%;
        right: auto;
        text-align: center;
        transform: translate(-50%, 0);
        box-shadow: none;
        border: 1px solid #40ab3e;
        background: #40ab3e;
        min-width: 200px; }
        .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a {
          height: auto;
          padding: 10px 30px 10px 30px;
          min-height: 25px;
          font-size: 18px;
          text-align: center;
          color: white;
          border-bottom: 1px solid #127633;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important; }
          .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a:before {
            content: "";
            width: 0;
            height: 0; }
          .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a:after {
            content: "";
            width: 0;
            height: 0; }
          .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a:hover, .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a:focus, .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li a:active {
            background: white;
            color: #000;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important; }
        .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li.active a {
          background: white;
          color: #000; }
        .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li:first-child a {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px; }
        .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li:last-child a {
          border-bottom: 0;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px; }
        .navbar-fixed-top #navbar .navbar-right li.dropdown .dropdown-menu li:after {
          content: "" !important;
          width: 0;
          height: 0; }
      .navbar-fixed-top #navbar .navbar-right li.dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0; }
      .navbar-fixed-top #navbar .navbar-right li:after {
        content: "\f111";
        font-family: FontAwesome;
        color: #40ab3e;
        font-size: 6px;
        position: absolute;
        right: 0;
        top: 40px;
        width: 10px;
        height: 10px; }
      .navbar-fixed-top #navbar .navbar-right li:last-child:after {
        content: "";
        width: 0;
        height: 0; }
    .navbar-fixed-top.fixed {
      background: rgba(0, 0, 0, 0.9);
      -webkit-box-shadow: 0 2px 3px -3px #686868;
      -moz-box-shadow: 0 2px 3px -3px #686868;
      box-shadow: 0 2px 3px -3px #686868; } }

header {
  width: 100%;
  height: 100vh;
  position: relative;
  background: url(/images/header/01.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: block; }
  header .bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75); }
  header .v-container,
  header .v-inner {
    height: 100vh; }
    header .v-container .logo,
    header .v-inner .logo {
      max-width: 350px;
      margin: 10px auto 0 auto;
      text-align: center;
      width: 350px;
      height: 350px;
      padding: 70px 0 0 0;
      position: relative;
      z-index: 10; }
      header .v-container .logo:before, header .v-container .logo:after,
      header .v-inner .logo:before,
      header .v-inner .logo:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 350px;
        height: 350px;
        z-index: 0; }
      header .v-container .logo:before,
      header .v-inner .logo:before {
        background: url(/images/bg-circle-big.png);
        background-size: 350px 350px;
        -webkit-transition: -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out; }
      header .v-container .logo:after,
      header .v-inner .logo:after {
        background: url(/images/bg-circle-small.png);
        background-size: 350px 350px;
        -webkit-transition: -webkit-transform .5s ease-in-out;
        transition: transform .5s ease-in-out; }
      header .v-container .logo img,
      header .v-inner .logo img {
        max-width: 200px;
        display: block;
        margin: 0 auto; }
      header .v-container .logo:hover:before,
      header .v-inner .logo:hover:before {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg); }
      header .v-container .logo:hover:after,
      header .v-inner .logo:hover:after {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg); }
    header .v-container .slogan,
    header .v-inner .slogan {
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      position: absolute;
      left: 20%;
      top: 50%;
      color: white;
      font-weight: 300;
      letter-spacing: -1px;
      text-align: center;
      max-width: 435px;
      font-size: 48px;
      line-height: 52px;
      margin: 0px 0 0 0;
      padding: 0;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
      header .v-container .slogan span,
      header .v-inner .slogan span {
        font-weight: 700;
        font-size: 84px;
        line-height: 75px; }
      header .v-container .slogan span > span,
      header .v-inner .slogan span > span {
        color: #40ab3e; }
      header .v-container .slogan strong,
      header .v-inner .slogan strong {
        font-weight: 700; }
    header .v-container .contact-icons,
    header .v-inner .contact-icons {
      margin: 45px 0 0 50px;
      float: left;
      position: relative;
      width: 350px;
      height: 175px; }
      header .v-container .contact-icons ul,
      header .v-inner .contact-icons ul {
        margin: 0;
        padding: 0;
        list-style: none;
        float: left;
        position: relative; }
        header .v-container .contact-icons ul li,
        header .v-inner .contact-icons ul li {
          float: left;
          position: relative;
          display: block;
          width: 175px;
          height: 175px; }
          header .v-container .contact-icons ul li.phone a span,
          header .v-inner .contact-icons ul li.phone a span {
            left: -40px;
            top: 40px; }
          header .v-container .contact-icons ul li.phone a i,
          header .v-inner .contact-icons ul li.phone a i {
            left: 31px;
            top: 26px; }
          header .v-container .contact-icons ul li a,
          header .v-inner .contact-icons ul li a {
            position: absolute;
            border: 5px solid #40ab3e;
            font-size: 32px;
            color: #40ab3e;
            width: 100px;
            height: 100px;
            display: block;
            border-radius: 50%;
            -webkit-transition: ease all 0.5s;
            transition: ease all 0.5s; }
            header .v-container .contact-icons ul li a span,
            header .v-inner .contact-icons ul li a span {
              position: absolute;
              opacity: 0;
              width: 175px;
              font-size: 1px;
              font-weight: 300;
              transition: ease all 0.5s;
              line-height: 22px;
              left: -40px;
              top: 24px; }
            header .v-container .contact-icons ul li a i,
            header .v-inner .contact-icons ul li a i {
              position: absolute;
              top: 23px;
              left: 26px;
              font-size: 40px;
              opacity: 1;
              -webkit-transition: ease all 0.5s;
              transition: ease all 0.5s; }
            header .v-container .contact-icons ul li a:hover, header .v-container .contact-icons ul li a:focus,
            header .v-inner .contact-icons ul li a:hover,
            header .v-inner .contact-icons ul li a:focus {
              width: 175px;
              height: 175px;
              margin-top: -25px;
              margin-left: -25px; }
              header .v-container .contact-icons ul li a:hover span, header .v-container .contact-icons ul li a:focus span,
              header .v-inner .contact-icons ul li a:hover span,
              header .v-inner .contact-icons ul li a:focus span {
                opacity: 1;
                font-size: 18px; }
              header .v-container .contact-icons ul li a:hover i, header .v-container .contact-icons ul li a:focus i,
              header .v-inner .contact-icons ul li a:hover i,
              header .v-inner .contact-icons ul li a:focus i {
                opacity: 0;
                top: 56px;
                left: 65px;
                font-size: 10px; }
          header .v-container .contact-icons ul li.email a:hover span, header .v-container .contact-icons ul li.email a:focus span,
          header .v-inner .contact-icons ul li.email a:hover span,
          header .v-inner .contact-icons ul li.email a:focus span {
            left: -4px;
            top: 62px; }
          header .v-container .contact-icons ul li.phone a:hover span, header .v-container .contact-icons ul li.phone a:focus span,
          header .v-inner .contact-icons ul li.phone a:hover span,
          header .v-inner .contact-icons ul li.phone a:focus span {
            left: -4px;
            top: 70px; }
  header .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0; }
  header .table-container {
    width: 100%;
    text-align: center;
    height: 600px;
    vertical-align: middle;
    display: table; }
    header .table-container .table-inner {
      display: table-cell;
      vertical-align: middle; }
      header .table-container .table-inner h1 {
        color: white;
        text-transform: uppercase;
        font-size: 55px; }

.slider-container {
  width: 100%;
  position: relative; }

#slider {
  height: 607px;
  min-height: 607px;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  #slider .owl-item {
    width: 100%;
    height: 600px; }
    #slider .owl-item .slide {
      position: relative;
      width: 100%;
      height: 600px;
      min-height: 600px;
      background-size: cover;
      background-position: top center; }
      #slider .owl-item .slide .overlay {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0; }
      #slider .owl-item .slide .owl--text {
        text-align: center;
        position: absolute;
        padding-top: 15px;
        top: 90px;
        width: 640px;
        height: 136px;
        right: 0;
        left: 0;
        margin: 0 auto;
        border-top-right-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topright: 5px; }
        #slider .owl-item .slide .owl--text h1 {
          font-size: 55px;
          font-weight: 500;
          margin: 0 0 40px 0;
          padding: 0 0 40px 0;
          color: white;
          position: relative;
          text-transform: uppercase; }
          #slider .owl-item .slide .owl--text h1:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 160px;
            height: 3px;
            background: #40ab3e; }
          #slider .owl-item .slide .owl--text h1:after {
            content: "\f111";
            font-family: FontAwesome;
            font-size: 18px;
            width: 18px;
            height: 18px;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -7px;
            position: absolute;
            color: #40ab3e; }
        #slider .owl-item .slide .owl--text p {
          margin: 0 0 30px 0;
          color: white;
          font-family: "Raleway", sans-serif;
          font-weight: 300;
          font-size: 22px;
          line-height: 38px; }
        #slider .owl-item .slide .owl--text .btn-red {
          margin-right: 8px; }
      #slider .owl-item .slide:after {
        content: "";
        position: absolute;
        background: #40ab3e;
        width: 100%;
        height: 5px;
        bottom: 0px;
        z-index: 0; }
  #slider .owl-controls {
    text-align: center; }
    #slider .owl-controls .owl-dots {
      height: 20px;
      width: 120px;
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: inline-block;
      zoom: 1; }
      #slider .owl-controls .owl-dots .owl-dot {
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        background: white;
        border: 3px solid #40ab3e;
        display: block;
        float: left;
        -webkit-backface-visibility: visible;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -ms-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px; }
        #slider .owl-controls .owl-dots .owl-dot.active {
          background: #40ab3e; }
        #slider .owl-controls .owl-dots .owl-dot:last-child {
          margin: 0; }

#voorbeelden {
  width: 100%;
  position: relative; }

#voorbeeld-slider {
  height: 370px; }
  #voorbeeld-slider .owl-carousel .owl-wrapper-outer,
  #voorbeeld-slider .owl-carousel .owl-stage-outer {
    z-index: 1 !important; }
  #voorbeeld-slider .owl-item {
    width: 100%;
    height: 370px; }
  #voorbeeld-slider .voorbeeld {
    width: 100%;
    height: 370px;
    border-right: 0;
    display: block; }
    #voorbeeld-slider .voorbeeld .overlay {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      left: 0;
      top: 0;
      display: table;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      transition: all 0.25s ease; }
      #voorbeeld-slider .voorbeeld .overlay .table-inner {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        text-align: center; }
        #voorbeeld-slider .voorbeeld .overlay .table-inner h4 {
          color: #40ab3e;
          margin: 0 0 5px 0;
          padding: 0;
          text-transform: uppercase;
          font-size: 24px; }
        #voorbeeld-slider .voorbeeld .overlay .table-inner p {
          color: white;
          margin: 0 0;
          opacity: 1;
          line-height: 1.9em;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          transition: all 0.2s ease; }
      #voorbeeld-slider .voorbeeld .overlay .btn {
        cursor: pointer;
        border: 0;
        border-bottom: 5px solid #127633;
        border-top: 5px solid transparent;
        position: absolute;
        width: 200px;
        height: 54px;
        opacity: 0;
        bottom: -54px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 8px 60px 12px 60px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease; }
        #voorbeeld-slider .voorbeeld .overlay .btn:hover, #voorbeeld-slider .voorbeeld .overlay .btn:focus {
          background: #40ab3e;
          border: 0;
          border-bottom: 5px solid #127633;
          border-top: 5px solid transparent; }
    #voorbeeld-slider .voorbeeld:hover .overlay, #voorbeeld-slider .voorbeeld:focus .overlay {
      background: rgba(0, 0, 0, 0.2); }
      #voorbeeld-slider .voorbeeld:hover .overlay .table-inner p, #voorbeeld-slider .voorbeeld:focus .overlay .table-inner p {
        margin: 0 0 30px 0; }
      #voorbeeld-slider .voorbeeld:hover .overlay .btn, #voorbeeld-slider .voorbeeld:focus .overlay .btn {
        bottom: 30px;
        opacity: 1; }

#contact .btn-green {
  padding: 8px 60px 12px 60px; }

.form-group {
  margin-bottom: 30px; }
  .form-group input[type="text"],
  .form-group input[type="tel"],
  .form-group input[type="email"],
  .form-group textarea {
    background: white;
    color: #686868;
    border-radius: 0;
    height: 50px;
    border: 3px solid #40ab3e;
    font-size: 16px; }
    .form-group input[type="text"]:focus, .form-group input[type="text"].active,
    .form-group input[type="tel"]:focus,
    .form-group input[type="tel"].active,
    .form-group input[type="email"]:focus,
    .form-group input[type="email"].active,
    .form-group textarea:focus,
    .form-group textarea.active {
      border: 3px solid #191919;
      color: #191919;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(139, 16, 121, 0); }
  .form-group textarea {
    height: 180px;
    resize: none; }

.alert {
  border-radius: 0;
  border-width: 3px; }

.alert-success {
  background: #82C881;
  border-color: #40ab3e; }

.form-control::-webkit-input-placeholder {
  color: #686868 !important; }

.form-control:focus::-webkit-input-placeholder {
  color: #191919 !important; }

.form-control:-moz-placeholder {
  color: #686868 !important;
  opacity: 1; }

.form-control:focus:-moz-placeholder {
  color: #191919 !important;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #686868 !important;
  opacity: 1; }

.form-control:focus::-moz-placeholder {
  color: #191919 !important;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #686868 !important; }

.form-control:focus:-ms-input-placeholder {
  color: #191919 !important; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace,serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.examples {
  padding: 50px 0;
  background: #191919; }

.overlay-content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  /*
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative;
    li {
      display: block;
      height: 20%;
      height: calc(100% / 5);
      min-height: 54px;
      a {
        font-size: 54px;
        font-weight: 300;
        display: block;
        color: #fff;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        &:hover,
        &:focus {
          color: #f0f0f0;
        }
      }
    }
  }
  */ }
  .overlay-content .overlay-close {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    background: url(/images/cross.png) no-repeat center center;
    text-indent: 200%;
    color: transparent;
    outline: none;
    z-index: 100; }
  .overlay-content .content-text {
    text-align: center;
    position: relative;
    top: 40%;
    height: 60%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: white; }
    .overlay-content .content-text h1 {
      color: white;
      text-transform: uppercase;
      font-size: 36px;
      margin: 0 0 30px 0; }
    .overlay-content .content-text p {
      color: white; }
    .overlay-content .content-text .image {
      margin: 0 auto;
      padding: 25px 50px;
      display: block; }

.fancybox-title-float-wrap {
  margin-bottom: 25px !important; }
  .fancybox-title-float-wrap .small {
    font-size: 16px !important;
    font-family: "Raleway", sans-serif;
    font-weight: 300; }

/* Effects */
.wrapper {
  background: #fff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }
  .wrapper.overlay-open {
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }

.overlay-contentscale {
  visibility: hidden;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: transform 0.5s, visibility 0s 0.5s; }

.overlay-contentscale.open {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

@media screen and (max-height: 30.5em) {
  .overlay-content nav {
    height: 70%;
    font-size: 34px; }
  .overlay-content ul li {
    min-height: 34px; } }

html, body {
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 1.9em;
  color: #686868;
  position: relative; }

html {
  height: 100%; }

body {
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
  padding-top: 0px; }

a {
  color: black; }
  a:hover, a:focus {
    outline: 0;
    color: #40ab3e; }

strong + strong {
  color: #40ab3e; }

.btn {
  box-shadow: none; }
  .btn:hover, .btn:focus {
    outline: 0;
    box-shadow: none; }

.btn-primary {
  padding: 8px 30px 8px 30px;
  background: white;
  font-weight: 400;
  letter-spacing: 1px;
  border-top: 1px solid #40ab3e;
  border-left: 1px solid #40ab3e;
  border-right: 1px solid #40ab3e;
  border-bottom: 5px solid #40ab3e;
  color: #40ab3e;
  border-radius: 50px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  position: relative; }
  .btn-primary:hover, .btn-primary:focus {
    outline: 0;
    background: white;
    color: #d64e4e;
    border-color: #d64e4e; }

.btn-large {
  font-size: 25px; }

h2, h3 {
  font-size: 30px;
  font-weight: 300;
  color: #40ab3e;
  text-align: center;
  position: relative;
  text-transform: uppercase; }

h2 {
  margin: 0 0 40px 0;
  padding: 0 0 40px 0; }
  h2:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 160px;
    height: 3px;
    background: #40ab3e; }
  h2:after {
    content: "\f111";
    font-family: FontAwesome;
    font-size: 18px;
    width: 18px;
    height: 18px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -7px;
    position: absolute;
    color: #40ab3e; }

p {
  margin: 0 0 32px 0;
  color: #686868;
  font-family: "Montserrat", sans-serif;
  font-weight: 300; }

.scroll-down {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px; }
  .scroll-down a {
    font-size: 32px;
    color: white; }
    .scroll-down a:hover, .scroll-down a:focus {
      color: #40ab3e; }

.padded {
  padding: 75px 0; }

.pattern {
  background: #ffffff url(/images/bg.jpg) no-repeat;
  background-size: cover;
  background-attachment: fixed; }

.intro {
  padding: 68px 0 75px 0; }
  .intro .item {
    margin: 20px auto 0 auto;
    text-align: center;
    padding: 10px;
    max-width: 100%; }
  .intro .intro-text {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0; }

.heading {
  background: #40ab3e;
  padding: 45px 0; }
  .heading h3 {
    margin: 0;
    padding: 0;
    color: white; }

.v-container {
  width: 100%;
  display: table; }
  .v-container .v-inner {
    display: table-cell;
    width: 100%;
    vertical-align: middle; }

a.example {
  width: 300px;
  margin-bottom: 60px;
  position: relative;
  float: left; }
  a.example .image-circle {
    width: 300px;
    height: 300px;
    display: block;
    margin: 0 auto;
    position: relative;
    padding-top: 15px; }
    a.example .image-circle:before, a.example .image-circle:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 300px;
      height: 300px;
      z-index: 0; }
    a.example .image-circle:before {
      background: url(/images/bg-circle-big.png);
      background-size: 300px 300px;
      -webkit-transition: -webkit-transform .5s ease-in-out;
      transition: transform .5s ease-in-out; }
    a.example .image-circle:after {
      background: url(/images/bg-circle-small.png);
      background-size: 300px 300px;
      -webkit-transition: -webkit-transform .5s ease-in-out;
      transition: transform .5s ease-in-out; }
    a.example .image-circle img {
      max-width: 270px;
      display: block;
      margin: 0 auto; }
  a.example .desc {
    float: left;
    width: 100%;
    position: relative; }
    a.example .desc h3 {
      min-height: 85px;
      width: 100%;
      float: left;
      text-transform: uppercase; }
      a.example .desc h3 span {
        font-size: 44px;
        font-weight: 700;
        display: block; }
      a.example .desc h3 span + span {
        font-size: 28px;
        font-weight: 300; }
    a.example .desc p {
      text-align: center;
      color: grey;
      float: left;
      width: 100%;
      min-height: 61px; }
      a.example .desc p.button {
        color: white;
        display: block;
        margin: 0 auto;
        min-height: 10px;
        border-radius: 25px;
        padding: 4px 30px;
        border-top: 1px solid white;
        border-right: 1px solid white;
        border-left: 1px solid white;
        border-bottom: 3px solid white; }
  a.example:hover {
    text-decoration: none; }
    a.example:hover .image-circle:before {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); }
    a.example:hover .image-circle:after {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg); }
    a.example:hover .desc p.button {
      background: white;
      color: #40ab3e;
      border-color: #40ab3e; }

.contact {
  width: 80px;
  height: 139px;
  position: fixed;
  right: -80px;
  top: 45%;
  z-index: 1001;
  background: #40ab3e;
  border: 1px solid #fff;
  border-right: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: ease all 0.2s; }
  .contact ul {
    list-style: none;
    margin: 15px 0 0 15px;
    padding: 0; }
    .contact ul li {
      float: left;
      width: 100%;
      margin-bottom: 7px; }
      .contact ul li a {
        border: 2px solid white;
        font-size: 32px;
        color: white;
        width: 50px;
        height: 50px;
        display: block;
        border-radius: 50%;
        text-align: center; }
        .contact ul li a i {
          font-size: 24px;
          margin-top: 11px; }
          .contact ul li a i.fa-phone {
            margin-top: 12px; }
        .contact ul li a:hover, .contact ul li a:focus {
          border-color: #127633;
          color: #127633; }
      .contact ul li:last-child {
        margin-bottom: 0; }
  .contact.slide {
    right: 0; }

.title {
  width: 300px;
  height: 300px;
  background: #40ab3e;
  color: white;
  border: 3px solid #40ab3e;
  margin: -150px auto 30px auto;
  border-radius: 50%;
  text-align: center;
  position: relative; }
  .title .v-container {
    height: 300px; }
    .title .v-container .v-inner {
      position: relative; }
      .title .v-container .v-inner h1 {
        margin: 0;
        padding: 0;
        color: white;
        text-transform: uppercase;
        width: 100%;
        float: left; }
        .title .v-container .v-inner h1 span {
          font-size: 40px;
          font-weight: 700;
          display: block; }
        .title .v-container .v-inner h1 span + span {
          font-size: 28px;
          font-weight: 300; }
      .title .v-container .v-inner .back {
        position: absolute;
        width: 50px;
        height: 40px;
        left: 0;
        right: 0;
        bottom: 25px;
        margin: 0 auto;
        color: white;
        text-align: center;
        font-size: 32px;
        z-index: 1; }
        .title .v-container .v-inner .back span {
          opacity: 0;
          position: absolute;
          right: -100px;
          top: 0;
          width: 80px;
          height: 40px;
          color: black;
          font-size: 24px;
          transition: ease all 0.3s; }
        .title .v-container .v-inner .back i {
          transition: ease all 0.3s; }
        .title .v-container .v-inner .back:hover, .title .v-container .v-inner .back:focus {
          color: black; }
          .title .v-container .v-inner .back:hover i, .title .v-container .v-inner .back:focus i {
            margin-left: -75px; }
          .title .v-container .v-inner .back:hover span, .title .v-container .v-inner .back:focus span {
            opacity: 1;
            right: -35px; }

.tooltip-inner {
  background: #127633;
  font-size: 16px; }

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #127633; }

.albums {
  padding: 0 0 30px 0; }

.item {
  padding: 5px 6px; }
  .item .thumbnail {
    border: 0;
    padding: 0;
    margin-bottom: 3px;
    position: relative; }
    .item .thumbnail a {
      position: relative;
      display: block;
      width: 100%;
      height: 100%; }
      .item .thumbnail a:before {
        content: "\f03e";
        font-family: FontAwesome;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 30px;
        width: 30px;
        height: 32px;
        line-height: 30px;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 101; }
      .item .thumbnail a:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(64, 171, 62, 0.7);
        opacity: 0;
        transition: ease all 0.3s; }
      .item .thumbnail a:hover:before, .item .thumbnail a:focus:before {
        top: 50%; }
      .item .thumbnail a:hover:before, .item .thumbnail a:hover:after, .item .thumbnail a:focus:before, .item .thumbnail a:focus:after {
        opacity: 1; }

footer {
  background: #40ab3e;
  color: white;
  text-align: center;
  padding: 15px 0 12px 0; }
  footer a {
    color: white; }
    footer a:hover {
      color: #f5f5f5;
      text-decoration: none; }
  footer ul.contactgegevens {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    zoom: 1;
    *display: inline; }
    footer ul.contactgegevens li {
      float: left;
      margin-right: 40px;
      padding-left: 24px;
      position: relative; }
      footer ul.contactgegevens li:first-child:before {
        content: "\f095";
        font-family: FontAwesome;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 3px; }
      footer ul.contactgegevens li:last-child {
        margin-right: 0; }
        footer ul.contactgegevens li:last-child:before {
          content: "\f0e0";
          font-family: FontAwesome;
          font-size: 15px;
          position: absolute;
          left: 0;
          top: 1px; }

@media only screen and (max-width: 1199px) {
  header .v-container .slogan,
  header .v-inner .slogan {
    max-width: 330px;
    font-size: 35px;
    line-height: 40px; }
    header .v-container .slogan span,
    header .v-inner .slogan span {
      font-size: 52px;
      line-height: 48px; }
  header .v-container .logo,
  header .v-inner .logo {
    width: 250px;
    height: 250px;
    padding-top: 58px; }
    header .v-container .logo img,
    header .v-inner .logo img {
      max-width: 150px; }
    header .v-container .logo:before, header .v-container .logo:after,
    header .v-inner .logo:before,
    header .v-inner .logo:after {
      width: 250px;
      height: 250px;
      background-size: 250px 250px; }
  .btn-round {
    width: 220px;
    height: 220px;
    line-height: 155px; }
  #slider .owl-item .slide .owl--text {
    right: 0;
    left: 0;
    margin: 0 auto; }
  a.example .image-circle {
    width: 250px;
    height: 250px; }
    a.example .image-circle:before, a.example .image-circle:after {
      width: 250px;
      height: 250px;
      background-size: 250px 250px; }
    a.example .image-circle img {
      max-width: 220px; } }

@media only screen and (max-width: 991px) {
  .navbar-fixed-top .container {
    width: 100%; }
  header .v-container .slogan,
  header .v-inner .slogan {
    max-width: 330px;
    font-size: 35px;
    line-height: 40px; }
    header .v-container .slogan span,
    header .v-inner .slogan span {
      font-size: 52px;
      line-height: 48px; }
  header .v-container .logo,
  header .v-inner .logo {
    width: 250px;
    height: 250px;
    padding-top: 58px; }
    header .v-container .logo img,
    header .v-inner .logo img {
      max-width: 150px; }
    header .v-container .logo:before, header .v-container .logo:after,
    header .v-inner .logo:before,
    header .v-inner .logo:after {
      width: 250px;
      height: 250px;
      background-size: 250px 250px; }
  .title {
    width: 200px;
    height: 200px;
    margin: -100px auto 30px auto; }
    .title .v-container {
      height: 200px; }
      .title .v-container .v-inner h1 {
        font-size: 1.5em; }
        .title .v-container .v-inner h1 span {
          font-size: 30px; }
        .title .v-container .v-inner h1 span + span {
          font-size: 18px; }
  .btn-round {
    width: 200px;
    height: 200px;
    font-size: 24px;
    line-height: 155px; }
    .btn-round:after {
      font-size: 40px;
      top: 45px; }
    .btn-round:before {
      top: 45px; }
    .btn-round:hover:before, .btn-round:focus:before {
      font-size: 40px; }
  a.example {
    width: 100%;
    float: left; }
    a.example .image-circle {
      width: 280px;
      height: 280px; }
      a.example .image-circle:before, a.example .image-circle:after {
        width: 280px;
        height: 280px;
        background-size: 280px 280px; }
      a.example .image-circle img {
        max-width: 250px; } }

@media only screen and (max-width: 767px) {
  body {
    padding-top: 55px; }
  .navbar-fixed-top {
    background: #191919;
    border: 0;
    margin: 0; }
    .navbar-fixed-top .navbar-header button {
      background: transparent;
      border: 0;
      float: right;
      width: 50px;
      height: 35px;
      position: relative;
      margin: 14px 15px 6px 15px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer; }
      .navbar-fixed-top .navbar-header button span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out; }
      .navbar-fixed-top .navbar-header button span:nth-child(1) {
        top: 0; }
      .navbar-fixed-top .navbar-header button span:nth-child(2) {
        top: 12px; }
      .navbar-fixed-top .navbar-header button span:nth-child(3) {
        top: 24px; }
    .navbar-fixed-top .navbar-header .menu-toggle.open span:nth-child(1) {
      top: 12px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg); }
    .navbar-fixed-top .navbar-header .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .navbar-fixed-top .navbar-header .menu-toggle.open span:nth-child(3) {
      top: 12px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg); }
    .navbar-fixed-top .navbar-header .navbar-brand {
      width: 275px;
      padding: 3px 5px; }
      .navbar-fixed-top .navbar-header .navbar-brand img {
        max-width: 50px;
        float: left;
        margin-right: 10px; }
      .navbar-fixed-top .navbar-header .navbar-brand span {
        margin-left: 10px;
        margin-top: 16px;
        float: left;
        font-size: 25px; }
  header .v-container .slogan,
  header .v-inner .slogan {
    left: 50%;
    top: 110px; }
  .intro {
    padding: 38px 0 45px 0; }
  #slider {
    height: calc(100vh - 55px);
    min-height: calc(100vh - 55px); }
    #slider .owl-item {
      height: calc(100vh - 55px); }
      #slider .owl-item .slide {
        height: calc(100vh - 55px) !important; }
        #slider .owl-item .slide .owl--text {
          width: 100%;
          padding-top: 0;
          top: 30px; }
          #slider .owl-item .slide .owl--text h1 {
            font-size: 30px; }
          #slider .owl-item .slide .owl--text p {
            font-size: 18px;
            line-height: 26px;
            padding: 0 15px; }
          #slider .owl-item .slide .owl--text .btn {
            min-width: 220px; }
          #slider .owl-item .slide .owl--text .btn-red {
            margin-bottom: 15px;
            margin-right: 0; }
    #slider .owl-controls .owl-dots {
      bottom: 15px; }
  footer ul.contactgegevens li {
    width: 100%;
    text-align: center;
    padding-left: 0; }
    footer ul.contactgegevens li:first-child:before, footer ul.contactgegevens li:last-child:before {
      content: "";
      width: 0;
      height: 0; }
    footer ul.contactgegevens li a {
      font-size: 20px;
      line-height: 32px; } }

@media only screen and (min-width: 768px) {
  #voorbeeld-slider,
  .owl-item,
  .owl-carousel {
    -webkit-transform-style: preserve-3d; } }
