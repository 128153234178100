.slider-container {
  width:100%;
  position:relative;
}

#slider {
  height:607px;
  min-height:607px;
  position:relative;
  overflow:hidden;
  z-index:1;

  .owl-item {
    width:100%;
    height:600px;

    .slide {
      position:relative;
      width:100%;
      height:600px;
      min-height:600px;
      background-size:cover;
      background-position: top center;

      .overlay {
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.3);
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        top:0;
      }

      .owl--text {
        text-align:center;
        position:absolute;
        padding-top:15px;
        top:90px;
        width:640px;
        height:136px;
        right:0;
        left:0;
        margin:0 auto;
        border-top-right-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topright: 5px;
        h1  {
          font-size:55px;
          font-weight:500;
          margin:0 0 40px 0;
          padding:0 0 40px 0;
          color:white;
          position:relative;
          text-transform:uppercase;
          &:before {
            content:"";
            position:absolute;
            bottom:0;
            left:0;
            right:0;
            margin:0 auto;
            width:160px;
            height:3px;
            background:$green;
          }
          &:after {
            content:"\f111";
            font-family: FontAwesome;
            font-size:18px;
            width:18px;
            height:18px;
            left:0;
            right:0;
            margin:0 auto;
            bottom:-7px;
            position:absolute;
            color:$green;
          }
        }
        p {
          margin:0 0 30px 0;
          color:white;
          font-family: $font-standard;
          font-weight:300;
          font-size:22px;
          line-height:38px;
        }
        .btn-red {
          margin-right:8px;
        }
      }
      &:after {
        content:"";
        position: absolute;
        background:$green;
        width:100%;
        height:5px;
        bottom:0px;
        z-index:0;
      }
    }
  }
  .owl-controls {
    text-align: center;
    .owl-dots {
      height:20px;
      width:120px;
      position:absolute;
      bottom:0px;
      left:0;
      right:0;
      margin:0 auto;
      display: inline-block;
      zoom: 1;

      .owl-dot {
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        background: white;
        border:3px solid $green;
        display: block;
        float:left;
        -webkit-backface-visibility: visible;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -ms-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        &.active {
          background:$green;
        }
        &:last-child {
          margin:0;
        }
      }
    }
  }
}
#voorbeelden {
  width:100%;
  position:relative;
}
#voorbeeld-slider {
  height:370px;

  .owl-carousel .owl-wrapper-outer,
  .owl-carousel .owl-stage-outer
  {
    z-index: 1 !important;
  }

  .owl-item {
    width:100%;
    height:370px;
  }
  .voorbeeld {
    width:100%;
    height:370px;
    border-right:0;
    display:block;
    .overlay {
      width:100%;
      height:100%;
      background:rgba(0,0,0,0.5);
      position:absolute;
      left:0;
      top:0;
      display:table;
      -webkit-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      transition: all 0.25s ease;
      .table-inner {
        display:table-cell;
        vertical-align: middle;
        width:100%;
        height:100%;
        padding:0 15px;
        text-align:center;
        h4 {
          color:$green;
          margin:0 0 5px 0;
          padding:0;
          text-transform:uppercase;
          font-size:24px;
        }
        p {
          color:white;
          margin:0 0;
          opacity:1;
          line-height: 1.9em;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
      }
      .btn {
        cursor:pointer;
        border:0;
        border-bottom:5px solid $greenShadow;
        border-top:5px solid transparent;
        position:absolute;
        width:200px;
        height:54px;
        opacity:0;
        bottom:-54px;
        left:0;
        right:0;
        margin:0 auto;
        padding:8px 60px 12px 60px;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        -ms-transition: all 0.2s ease;
        transition: all 0.2s ease;
        &:hover,
        &:focus {
          background:$green;
          border:0;
          border-bottom:5px solid $greenShadow;
          border-top:5px solid transparent;
        }
      }

    }
    &:hover,
    &:focus {
      .overlay {
        background:rgba(0,0,0,0.2);
        .table-inner {
          p {
            margin:0 0 30px 0;
          }
        }
        .btn {
          bottom:30px;
          opacity:1;
        }
      }
    }
  }
}