@media only screen and (max-width: 1199px) {
  header {
    .v-container,
    .v-inner {
      .slogan {
        max-width:330px;
        font-size:35px;
        line-height:40px;
        span {
          font-size:52px;
          line-height:48px;
        }
      }
      .logo {
        width:250px;
        height:250px;
        padding-top:58px;

        img {
          max-width:150px;
        }

        &:before,
        &:after {
          width:250px;
          height:250px;
          background-size: 250px 250px;
        }
      }
    }
  }

  .btn-round {
    width:220px;
    height:220px;
    line-height: 155px;
  }

  #slider {
    .owl-item {
      .slide {
        .owl--text {
          right:0;
          left:0;
          margin:0 auto;
        }
      }
    }
  }

  a.example {
    .image-circle {
      width:250px;
      height:250px;
      &:before,
      &:after {
        width:250px;
        height:250px;
        background-size: 250px 250px;
      }
      img {
        max-width:220px;
      }
    }
    .desc {
      p {
        &.button {

        }
      }
    }
  }

}