html, body {
  padding:0;
  margin:0;
  font-family: $font-standard;
  font-size:16px;
  line-height:1.9em;
  color:$grey;
  position:relative;
}
html {
  height:100%;
}
body {
  min-height:100%;
  height:100%;
  overflow-x:hidden;
  padding-top:0px;
}
a {
  color:black;
  &:hover,
  &:focus {
    outline:0;
    color:$green;
  }
}

strong + strong {
  color:$green;
}

.btn {
  box-shadow:none;
  &:hover,
  &:focus {
    outline: 0;
    box-shadow:none;
  }
}

.btn-primary {
  padding:8px 30px 8px 30px;
  background:white;
  font-weight:400;
  letter-spacing:1px;
  border-top:1px solid $green;
  border-left:1px solid $green;
  border-right:1px solid $green;
  border-bottom:5px solid $green;
  color:$green;
  border-radius:50px;
  font-size:20px;
  text-transform:uppercase;
  font-family: $font-montserrat;
  position:relative;

  &:hover,
  &:focus {
    outline:0;
    background:white;
    color:$red;
    border-color:$red;
  }
}

.btn-large {
  font-size:25px;
}

h2, h3 {
  font-size:30px;
  font-weight:300;
  color:$green;
  text-align:center;
  position:relative;
  text-transform:uppercase;
}
h2  {
  margin:0 0 40px 0;
  padding:0 0 40px 0;
  &:before {
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    width:160px;
    height:3px;
    background:$green;
  }
  &:after {
    content:"\f111";
    font-family: FontAwesome;
    font-size:18px;
    width:18px;
    height:18px;
    left:0;
    right:0;
    margin:0 auto;
    bottom:-7px;
    position:absolute;
    color:$green;
  }
}
p {
  margin:0 0 32px 0;
  color:$grey;
  font-family: $font-montserrat;
  font-weight:300;
}

.scroll-down {
  position:absolute;
  bottom:5px;
  left:0;
  right:0;
  margin:0 auto;
  width:40px;
  height:40px;
  a {
    font-size:32px;
    color:white;
    &:hover,
    &:focus {
      color:$green;
    }
  }
}

.padded {
  padding:75px 0;
}
.pattern {
  background: #ffffff url(/images/bg.jpg) no-repeat;
  background-size:cover;
  background-attachment:fixed;
}
.intro {
  padding:68px 0 75px 0;
  .item {
    margin:20px auto 0 auto;
    text-align:center;
    padding:10px;
    max-width:100%;
  }
  .intro-text {
    font-size:20px;
    font-family: $font-montserrat;
    font-weight:300;
    line-height:36px;
    letter-spacing:0;
  }
}

.heading {
  background:$green;
  padding:45px 0;
  h3 {
    margin:0;
    padding:0;
    color:white;
  }
}

.v-container {
  width:100%;
  display:table;
  .v-inner {
    display:table-cell;
    width:100%;
    vertical-align: middle;
  }
}

a.example {
  width:300px;
  margin-bottom:60px;
  position:relative;
  float:left;
  .image-circle {
    width:300px;
    height:300px;
    display:block;
    margin:0 auto;
    position:relative;
    padding-top:15px;
    &:before,
    &:after {
      content:"";
      position:absolute;
      left:0;
      top:0;
      width:300px;
      height:300px;
      z-index:0;
    }
    &:before {
      background: url(/images/bg-circle-big.png);
      background-size: 300px 300px;
      -webkit-transition: -webkit-transform .5s ease-in-out;
      transition:         transform .5s ease-in-out;
    }
    &:after {
      background: url(/images/bg-circle-small.png);
      background-size: 300px 300px;
      -webkit-transition: -webkit-transform .5s ease-in-out;
      transition:         transform .5s ease-in-out;
    }
    img {
      max-width:270px;
      display:block;
      margin:0 auto;
    }
  }
  .desc {
    float:left;
    width:100%;
    position:relative;
    h3 {
      min-height:85px;
      width:100%;
      float:left;
      text-transform: uppercase;
      span {
        font-size:44px;
        font-weight:700;
        display:block;
      }
      span + span {
        font-size:28px;
        font-weight:300;
      }
    }
    p {
      text-align:center;
      color:grey;
      float:left;
      width:100%;
      min-height:61px;
      &.button {
        color:white;
        display:block;
        margin:0 auto;
        min-height:10px;
        border-radius:25px;
        padding:4px 30px;
        border-top:1px solid white;
        border-right:1px solid white;
        border-left:1px solid white;
        border-bottom:3px solid white;
      }
    }
  }
  &:hover {
    text-decoration:none;
    .image-circle {
      &:before {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
      &:after {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
      }
    }
    .desc {
      p {
        &.button {
          background:white;
          color:$green;
          border-color:$green;
        }
      }
    }
  }
}
.contact {
  width:80px;
  height:139px;
  position:fixed;
  right:-80px;
  top:45%;
  z-index:1001;
  background:$green;
  border:1px solid #fff;
  border-right:0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: ease all 0.2s;
  ul {
    list-style:none;
    margin:15px 0 0 15px;
    padding:0;
    li {
      float:left;
      width:100%;
      margin-bottom:7px;
      a {
        border:2px solid white;
        font-size:32px;
        color:white;
        width:50px;
        height:50px;
        display:block;
        border-radius:50%;
        text-align:center;
        i {
          font-size:24px;
          margin-top:11px;
          &.fa-phone {
            margin-top:12px;
          }
        }
        &:hover,
        &:focus {
          border-color:$greenShadow;
          color:$greenShadow;
        }
      }
      &:last-child {
        margin-bottom:0;
      }
    }
  }
  &.slide {
    right:0;
  }
}

.title {
  width:300px;
  height:300px;
  background:$green;
  color:white;
  border:3px solid $green;
  margin:-150px auto 30px auto;
  border-radius:50%;
  text-align:center;
  position:relative;
  .v-container {
    height:300px;
    .v-inner {
      position:relative;
      h1 {
        margin:0;
        padding:0;
        color:white;
        text-transform:uppercase;
        width:100%;
        float:left;
        span {
          font-size:40px;
          font-weight:700;
          display:block;
        }
        span + span {
          font-size:28px;
          font-weight:300;
        }
      }
      .back {
        position:absolute;
        width:50px;
        height:40px;
        left:0;
        right:0;
        bottom:25px;
        margin:0 auto;
        color:white;
        text-align:center;
        font-size:32px;
        z-index:1;
        span {
          opacity:0;
          position:absolute;
          right:-100px;
          top:0;
          width:80px;
          height:40px;
          color:black;
          font-size:24px;
          transition:ease all 0.3s;
        }
        i {
          transition:ease all 0.3s;
        }
        &:hover,
        &:focus {
          color:black;
          i {
            margin-left:-75px;
          }
          span {
            opacity:1;
            right:-35px;
          }
        }
      }
    }
  }
}

.tooltip-inner{
  background:$greenShadow;
  font-size:16px;
}
.tooltip.left .tooltip-arrow {
  top:50%;
  right:0;
  margin-top:-5px;
  border-top:5px solid transparent;
  border-bottom:5px solid transparent;
  border-left:5px solid $greenShadow;
}

.albums {
  padding:0 0 30px 0;
}

.item {
  padding:5px 6px;
  .thumbnail {
    border:0;
    padding:0;
    margin-bottom:3px;
    position:relative;
    a {
      position:relative;
      display:block;
      width:100%;
      height:100%;
      &:before {
        content:"\f03e";
        font-family: FontAwesome;
        position:absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        color:white;
        font-size:30px;
        width:30px;
        height:32px;
        line-height:30px;
        text-align:center;
        opacity:0;
        transition: all 0.3s ease;
        z-index:101;
      }
      &:after {
        content:"";
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        z-index:100;
        background:rgba(64,171,62,0.7);
        opacity:0;
        transition: ease all 0.3s;
      }
      &:hover,
      &:focus {
        &:before {
          top:50%;
        }
        &:before,
        &:after {
          opacity:1;
        }
      }
    }
  }
}
