@media only screen and (min-width : 768px) {
  .menu-toggle {
    display:none;
  }
  .navbar-fixed-top {
    background:rgba(0,0,0,0.4);
    border:0;
    border-bottom:0 solid rgba(255,255,255,0.2);
    transition: ease all 0.2s;
    margin:0;

    .navbar-header {
      .navbar-brand {
        height:110px;
        padding:5px 15px;
        span {
          float:left;
          margin-left:20px;
          margin-top:38px;
          font-size:30px;
          color:white;
          font-weight:300;
          font-family: $font-montserrat;
          strong {
            font-weight:700;
          }
          strong + strong {
            color:$green;
          }
        }
        img {
          float:left;
          max-width:105px;
        }
      }
    }
    #navbar {
      .navbar-right {
        li {
          position:relative;
          a {
            padding:45px 30px 45px 20px;
            overflow:hidden;
            font-family: $font-standard;
            text-transform:lowercase;
            font-size:18px;
            color:white;
            letter-spacing:1px;
            text-decoration: none;
            &:before {
              content:"\f0d8";
              font-family: FontAwesome;
              left:0;
              right:0;
              bottom:-14px;
              position: absolute;
              display:block;
              margin:0 auto;
              width:10px;
              height:14px;
              color:$green;
              line-height:20px;
              font-size:20px;
              transition: all 0.2s ease;
              z-index:1000;
            }
            &:hover,
            &:focus {
              color:$green;
              position: relative;
              &:before {
                bottom:-1px;
              }
            }
          }
          &.active {
            a {
              background:transparent;
              color:$green;
              position: relative;
              &:before {
                content:"\f0d8";
                font-family: FontAwesome;
                left:0;
                right:0;
                bottom:-1px;
                position: absolute;
                display:block;
                margin:0 auto;
                width:10px;
                height:14px;
                color:$green;
                line-height:20px;
                font-size:20px;
                z-index:1000;
                text-indent: 0;
              }
            }
          }
          &.dropdown {
            .dropdown-menu {
              padding:0;
              border-radius:5px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              left: 50%;
              right: auto;
              text-align: center;
              transform: translate(-50%, 0);
              box-shadow:none;
              border:1px solid $green;
              background:$green;
              min-width:200px;
              li {
                a {
                  height:auto;
                  padding:10px 30px 10px 30px;
                  min-height:25px;
                  font-size:18px;
                  text-align: center;
                  color:white;
                  border-bottom:1px solid $greenShadow;
                  border-top-left-radius: 0 !important;
                  border-top-right-radius: 0 !important;
                  &:before {
                    content:"";
                    width:0;
                    height:0;
                  }
                  &:after {
                    content:"";
                    width:0;
                    height:0;
                  }
                  &:hover,
                  &:focus,
                  &:active {
                    background:white;
                    color:#000;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                  }
                }
                &.active {
                  a {
                    background:white;
                    color:#000;
                  }
                }
                &:first-child {
                  a {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                  }
                }
                &:last-child {
                  a {
                    border-bottom:0;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                  }
                }
                &:after {
                  content:"" !important;
                  width:0;
                  height:0;
                }
              }
            }

            &:hover {
              .dropdown-menu {
                display: block;
                margin-top:0;
              }
            }
          }
          &:after {
            content:"\f111";
            font-family: FontAwesome;
            color:$green;
            font-size:6px;
            position: absolute;
            right:0;
            top:40px;
            width:10px;
            height:10px;
          }
          &:last-child {
            &:after {
              content:"";
              width:0;
              height:0;
            }
          }
        }
      }
    }
    &.fixed {
      background:rgba(0,0,0,0.9);
      -webkit-box-shadow: 0 2px 3px -3px $grey;
      -moz-box-shadow: 0 2px 3px -3px $grey;
      box-shadow: 0 2px 3px -3px $grey;
    }
  }
}