.examples {
  padding:50px 0;
  background:$greyDark;
}


.overlay-content {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.9);

  .overlay-close {
    width: 80px;
    height: 80px;
    position: absolute;
    right: 20px;
    top: 20px;
    overflow: hidden;
    border: none;
    background: url(/images/cross.png) no-repeat center center;
    text-indent: 200%;
    color: transparent;
    outline: none;
    z-index: 100;
  }

  .content-text {
    text-align: center;
    position: relative;
    top: 40%;
    height: 60%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color:white;
    h1 {
      color:white;
      text-transform:uppercase;
      font-size:36px;
      margin:0 0 30px 0;
    }
    p {
      color:white;
    }
    .image {
      margin:0 auto;
      padding:25px 50px;
      display:block;
    }
  }
/*
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative;
    li {
      display: block;
      height: 20%;
      height: calc(100% / 5);
      min-height: 54px;
      a {
        font-size: 54px;
        font-weight: 300;
        display: block;
        color: #fff;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        &:hover,
        &:focus {
          color: #f0f0f0;
        }
      }
    }
  }
  */
}

.fancybox-title-float-wrap {
  margin-bottom:25px !important;
  .small {
    font-size:16px !important;
    font-family: $font-standard;
    font-weight:300;
  }
}
/* Effects */
.wrapper {
  background: #fff;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  &.overlay-open {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.overlay-contentscale {
  visibility: hidden;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.5s, visibility 0s 0.5s;
  transition: transform 0.5s, visibility 0s 0.5s;
}

.overlay-contentscale.open {
  visibility: visible;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
}

@media screen and (max-height: 30.5em) {
  .overlay-content nav {
    height: 70%;
    font-size: 34px;
  }
  .overlay-content ul li {
    min-height: 34px;
  }
}