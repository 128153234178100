#contact {
  .btn-green {
    padding:8px 60px 12px 60px;
  }
}

.form-group {
  margin-bottom:30px;

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea {
    background:white;
    color:$grey;
    border-radius:0;
    height:50px;
    border:3px solid $green;
    font-size:16px;
    &:focus,
    &.active {
      border:3px solid $greyDark;
      color:$greyDark;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(139, 16, 121, 0);
    }
  }
  textarea {
    height:180px;
    resize:none;
  }
}

.alert {
  border-radius:0;
  border-width:3px;
}
.alert-success {
  background:#82C881;
  border-color:$green;
}


.form-control::-webkit-input-placeholder {
  color:    $grey !important;
}
.form-control:focus::-webkit-input-placeholder {
  color:    $greyDark !important;
}

.form-control:-moz-placeholder {
  color:    $grey !important;
  opacity:  1;
}
.form-control:focus:-moz-placeholder {
  color:    $greyDark !important;
  opacity:  1;
}

.form-control::-moz-placeholder {
  color:    $grey !important;
  opacity:  1;
}
.form-control:focus::-moz-placeholder {
  color:    $greyDark !important;
  opacity:  1;
}

.form-control:-ms-input-placeholder {
  color:    $grey !important;
}
.form-control:focus:-ms-input-placeholder {
  color:    $greyDark !important;
}