@media only screen and (max-width: 767px) {
  body {
    padding-top:55px;
  }
  .navbar-fixed-top {
    background:$greyDark;
    border:0;
    margin:0;
    .navbar-header {
      button {
        background:transparent;
        border:0;
        float:right;
        width: 50px;
        height: 35px;
        position: relative;
        margin:14px 15px 6px 15px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        span {
          display: block;
          position: absolute;
          height: 4px;
          width: 100%;
          background: white;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
        }
        span:nth-child(1) {
          top: 0;
        }

        span:nth-child(2) {
          top: 12px;
        }

        span:nth-child(3) {
          top: 24px;
        }
      }
      .menu-toggle.open span:nth-child(1) {
        top: 12px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      .menu-toggle.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      .menu-toggle.open span:nth-child(3) {
        top: 12px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
      .navbar-brand {
        width:275px;
        padding: 3px 5px;
        img {
          max-width: 50px;
          float: left;
          margin-right: 10px;
        }
        span {
          margin-left:10px;
          margin-top:16px;
          float:left;
          font-size:25px;
        }
      }
    }
  }

  header {
    .v-container,
    .v-inner {
      .slogan {
        left:50%;
        top:110px;
      }
    }
  }

  .intro {
    padding:38px 0 45px 0;
  }

  #slider {
    height:calc(100vh - 55px);
    min-height:calc(100vh - 55px);
    .owl-item {
      height:calc(100vh - 55px);
      .slide {
        height:calc(100vh - 55px) !important;
        .owl--text {
          width:100%;
          padding-top:0;
          top:30px;
          h1 {
            font-size:30px;
          }
          p {
            font-size:18px;
            line-height:26px;
            padding:0 15px;
          }
          .btn {
            min-width:220px;
          }
          .btn-red {
            margin-bottom:15px;
            margin-right:0;
          }
        }
      }
    }
    .owl-controls {
      .owl-dots {
        bottom:15px;
      }
    }
  }

  footer {
    ul.contactgegevens {
      li {
        width:100%;
        text-align:center;
        padding-left:0;
        &:first-child,
        &:last-child{
          &:before {
            content:"";
            width:0;
            height:0;
          }
        }
        a {
          font-size:20px;
          line-height:32px;
        }

      }
    }
  }
}
@media only screen and (min-width: 768px) {
  #voorbeeld-slider,
  .owl-item,
  .owl-carousel {
    -webkit-transform-style: preserve-3d;
  }
}
