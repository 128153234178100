header {
  width:100%;
  height:100vh;
  position:relative;
  background:url(/images/header/01.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size:cover;
  background-attachment: fixed;
  display:block;

  .bg {
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.75);
  }

  .v-container,
  .v-inner {
    height:100vh;
    .logo {
      max-width:350px;
      margin:10px auto 0 auto;
      text-align:center;
      width:350px;
      height:350px;
      padding:70px 0 0 0;
      position:relative;
      z-index:10;
      &:before,
      &:after {
        content:"";
        position:absolute;
        left:0;
        top:0;
        width:350px;
        height:350px;
        z-index:0;
      }
      &:before {
        background: url(/images/bg-circle-big.png);
        background-size: 350px 350px;
        -webkit-transition: -webkit-transform .5s ease-in-out;
        transition:         transform .5s ease-in-out;
      }
      &:after {
        background: url(/images/bg-circle-small.png);
        background-size: 350px 350px;
        -webkit-transition: -webkit-transform .5s ease-in-out;
        transition:         transform .5s ease-in-out;
      }
      img {
        max-width:200px;
        display:block;
        margin:0 auto;
      }
      &:hover {
        &:before {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
        &:after {
          -webkit-transform: rotate(-360deg);
          transform: rotate(-360deg);
        }
      }
    }
    .slogan {
      font-family: $font-montserrat;
      text-transform: uppercase;
      position:absolute;
      left: 20%;
      top:50%;
      color:white;
      font-weight:300;
      letter-spacing:-1px;
      text-align:center;
      max-width:435px;
      font-size:48px;
      line-height:52px;
      margin:0px 0 0 0;
      padding:0;
      -webkit-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      span {
        font-weight:700;
        font-size:84px;
        line-height:75px;
      }
      span > span {
        color:$green;
      }
      strong {
        font-weight:700;
      }
    }
    .contact-icons {
      margin:45px 0 0 50px;
      float:left;
      position:relative;
      width:350px;
      height:175px;
      ul {
        margin:0;
        padding:0;
        list-style:none;
        float:left;
        position:relative;
        li {
          float:left;
          position:relative;
          display: block;
          width: 175px;
          height: 175px;
          &.phone {
            a {
              span {
                left: -40px;
                top: 40px;
              }
              i {
                left:31px;
                top:26px;
              }
            }
          }
          a {
            position:absolute;
            border:5px solid $green;
            font-size:32px;
            color:$green;
            width:100px;
            height:100px;
            display:block;
            border-radius:50%;
            -webkit-transition: ease all 0.5s;
            transition: ease all 0.5s;
            span {
              position:absolute;
              opacity:0;
              width: 175px;
              font-size: 1px;
              font-weight: 300;
              transition: ease all 0.5s;
              line-height: 22px;
              left: -40px;
              top: 24px;
            }
            i {
              position:absolute;
              top:23px;
              left:26px;
              font-size:40px;
              opacity:1;
              -webkit-transition: ease all 0.5s;
              transition: ease all 0.5s;
            }
            &:hover,
            &:focus {
              width:175px;
              height:175px;
              margin-top:-25px;
              margin-left:-25px;
              span {
                opacity:1;
                font-size:18px;
              }
              i {
                opacity:0;
                top:56px;
                left:65px;
                font-size:10px;
              }
            }
          }
          &.email {
            a {
              &:hover,
              &:focus {
                span {
                  left: -4px;
                  top: 62px;
                }
              }
            }
          }
          &.phone {
            a {
              &:hover,
              &:focus {
                span {
                  left: -4px;
                  top: 70px;
                }
              }
            }
          }
        }
      }
    }
  }

  .overlay {
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.6);
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
  }
  .table-container {
    width:100%;
    text-align:center;
    height:600px;
    vertical-align: middle;
    display: table;
    .table-inner {
      display:table-cell;
      vertical-align: middle;
      h1 {
        color:white;
        text-transform: uppercase;
        font-size:55px;
      }
    }
  }
}
