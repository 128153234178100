footer {
  background:$green;
  color:white;
  text-align:center;
  padding:15px 0 12px 0;
  a {
    color:white;
    &:hover {
      color:$greyLight;
      text-decoration: none;
    }
  }
  ul.contactgegevens {
    list-style: none;
    display: inline-block;
    margin: 0;
    padding: 0;
    zoom:1;
    *display: inline;
    li {
      float:left;
      margin-right:40px;
      padding-left:24px;
      position: relative;
      &:first-child {
        &:before {
          content:"\f095";
          font-family: FontAwesome;
          font-size:16px;
          position: absolute;
          left:0;
          top:3px;
        }
      }
      &:last-child {
        margin-right:0;
        &:before {
          content:"\f0e0";
          font-family: FontAwesome;
          font-size:15px;
          position: absolute;
          left:0;
          top:1px;
        }
      }
    }
  }
}